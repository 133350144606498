import React from 'react'
import { graphql } from 'gatsby'

import HelmetWrapper from '../components/helmet-wrapper'
import Layout from '../components/layout'
import ProjectListing from '../components/projects/project-listing'

const ProjectIndex = ({ location, data }) => {
  const projects = data.allContentfulProject.edges.map(edge => edge.node)

  return (
    <Layout location={location}>
      <div>
        <HelmetWrapper pageTitle="Projects" />
        <div className="p-4">
          <div className="md:max-w-5xl md:mx-auto md:mt-6">
            <ProjectListing projects={projects} includeOwner={true} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectIndex

export const pageQuery = graphql`
  query ProjectIndexQuery {
    allContentfulProject {
      edges {
        node {
          id
          title
          slug
          when
          with
          owner
          description {
            description
          }
        }
      }
    }
  }
`
